import Copy from "../../assets/images/Copy";
import { useSelector } from "react-redux";
import { copyClipboard, translateMessage } from "../../helper/constant";

const CopyCheckoutID = ({ className = "" }) => {
  const modalState = useSelector((state: ReduxState) => state.widgetReducer);

  const str1 = translateMessage(
    "Please note your checkout id for any transaction details",
  );

  return (
    <div className="position-relative">
      <div
        className="copy-checkout cursor"
        onClick={() => copyClipboard(modalState.checkoutData.checkoutId!)}
      >
        <h4># {modalState.checkoutDetails.checkoutId}</h4>
        <div>
          <Copy />
        </div>
      </div>
      <div className={`copy-checkout-subtext ${className}`}>{str1}</div>
    </div>
  );
};

export default CopyCheckoutID;
