import * as actionTypes from "../actions/actionTypes";

const initialState: RedState = {
  tokenAndPairPrecisionData: {
    data: null,
    error: null,
  },
  metadata: null,
};

// Globiance
const tokenAndPairPrecisionDataStart = (
  state: RedState,
  action: PrecisionAction,
) => {
  return {
    ...state,
    tokenAndPairPrecisionData: {
      ...state.tokenAndPairPrecisionData,
      data: null,
      error: null,
    },
  };
};

const tokenAndPairPrecisionDataSuccess = (
  state: RedState,
  action: PrecisionAction,
) => {
  return {
    ...state,
    tokenAndPairPrecisionData: {
      ...state.tokenAndPairPrecisionData,
      data: action.data,
      error: null,
    },
  };
};

const tokenAndPairPrecisionDataFail = (
  state: RedState,
  action: PrecisionAction,
) => {
  return {
    ...state,
    tokenAndPairPrecisionData: {
      ...state.tokenAndPairPrecisionData,
      error: action.error,
      data: null,
    },
  };
};

const metadataSuccess = (state: RedState, action: any) => {
  return {
    ...state,
    metadata: action.data,
  };
};

// Global Reducer
const globalReducer = (state = initialState, action: PrecisionAction) => {
  switch (action.type) {
    case actionTypes.GET_TOKEN_AND_PAIR_PRECISION_DATA_START:
      return tokenAndPairPrecisionDataStart(state, action);
    case actionTypes.GET_TOKEN_AND_PAIR_PRECISION_DATA_SUCCESS:
      return tokenAndPairPrecisionDataSuccess(state, action);
    case actionTypes.GET_TOKEN_AND_PAIR_PRECISION_DATA_FAIL:
      return tokenAndPairPrecisionDataFail(state, action);
    case actionTypes.GET_METADATA_SUCCESS:
      return metadataSuccess(state, action);
    default:
      return {
        ...state,
      };
  }
};

export default globalReducer;
