import { useEffect, useState } from "react";
import Container from "../components/Container";
import Header from "../components/Headers";
import TopBar from "../components/TopBar";
import "../scss/main.scss";
import { useDispatch } from "react-redux";
import { updateTheme } from "../store/actions/widgetActions";

export const App = () => {
  const dispatch = useDispatch();

  const [isDarkMode, setIsDarkMode] = useState<boolean>(false);

  useEffect(() => {
    dispatch(updateTheme(isDarkMode ? "dark" : "light"));
  }, [isDarkMode]);

  return (
    <>
      <Header />
      <TopBar isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} />
      <Container isDarkMode={isDarkMode} />
    </>
  );
};
