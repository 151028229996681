import { useHistory } from "react-router";
import React, { useState, useEffect, ChangeEvent } from "react";

import styles from "./topbar.module.scss";
import { ReactComponent as SunIcon } from "../../assets/svg/ph_sun.svg";
import { ReactComponent as MoonIcon } from "../../assets/svg/moon.svg";
import { getQueryParameters } from "../../helper/constant";

interface Prop {
  isDarkMode: boolean;
  setIsDarkMode: Function;
}

const TopBar: React.FC<Prop> = ({ isDarkMode, setIsDarkMode }) => {
  const [language, setLanguage] = useState<string>("");
  const [isHome, setIsHome] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme === "dark") {
      setIsDarkMode(true);
      document.documentElement.setAttribute("data-theme", "dark");
      localStorage.setItem("theme", "dark");
    } else {
      setIsDarkMode(false);
      document.documentElement.setAttribute("data-theme", "light");
      localStorage.setItem("theme", "light");
    }
  }, []);

  useEffect(() => {
    const currentURL = window.location.href;
    const searchParams = getQueryParameters(currentURL);

    setIsHome(searchParams?.checkoutId && searchParams?.apiKey ? false : true);

    if (searchParams?.lang) {
      if (
        [
          "en",
          "es",
          "ar",
          "de",
          "fr",
          "it",
          "ja",
          "ko",
          "pt",
          "tr",
          "zh-cn",
        ].includes(searchParams?.lang.toLowerCase())
      ) {
        setLanguage(searchParams.lang.toLowerCase());
        localStorage.setItem("lang", searchParams.lang.toLowerCase());
      } else {
        setLanguage(localStorage.getItem("lang") || "en");
      }
    }
  }, [window.location.href]);

  const handleLanguageChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const newLang = e.target.value;
    setLanguage(newLang);
    localStorage.setItem("lang", newLang);
    const currentURL = new URL(window.location.href);
    currentURL.searchParams.set("lang", newLang);
    window.history.replaceState(null, "", currentURL.toString());
    window.location.reload();
  };

  const handleThemeToggle = () => {
    setIsDarkMode((prevMode: any) => {
      const newMode = !prevMode;
      document.documentElement.setAttribute(
        "data-theme",
        newMode ? "dark" : "light",
      );
      localStorage.setItem("theme", newMode ? "dark" : "light");
      return newMode;
    });
  };

  return (
    <div className={`${styles.settings} ${isHome ? styles.full : styles.mini}`}>
      <div className={styles.languageSelectWrapper}>
        <select
          value={language}
          onChange={handleLanguageChange}
          className={styles.languageSelect}
        >
          <option value="en">EN</option>
          <option value="es">ES</option>
          <option value="ar">AR</option>
          <option value="de">DE</option>
          <option value="fr">FR</option>
          <option value="it">IT</option>
          <option value="ja">JA</option>
          <option value="ko">KO</option>
          <option value="pt">PT</option>
          <option value="tr">TR</option>
          <option value="zh-cn">ZH-CN</option>
        </select>
      </div>
      <label className={styles.themeToggle}>
        <input
          type="checkbox"
          checked={isDarkMode}
          onChange={handleThemeToggle}
          className={styles.themeSwitch}
        />
        <span className={styles.slider}>
          {isDarkMode ? (
            <MoonIcon className={styles.icon} />
          ) : (
            <SunIcon className={styles.icon} />
          )}
        </span>
      </label>
    </div>
  );
};

export default TopBar;
