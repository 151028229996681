import Cancel from "../../assets/images/Cancel";
import { useDispatch, useSelector } from "react-redux";
import * as action from "../../store/actions";
import {
  axiosInstance,
  dispatchNotifications,
  translateMessage,
} from "../../helper/constant";
import { Close } from "../..";

const CancelScreen = () => {
  const dispatch = useDispatch();
  const modalState = useSelector((state: ReduxState) => state.widgetReducer);

  const cancelCheckout = () => {
    let data = {
      apiKey: modalState.widgetData.apikey,
      checkoutId: modalState.checkoutData.checkoutId,
    };
    axiosInstance
      .post("/widget/cancel-checkout", data)
      .then((res) => {
        if (res.status === 200) {
          dispatchNotifications({
            message: translateMessage("Checkout Cancellation was successful"),
            color: "green",
          });
          dispatch(action.updateWidgetScreenState("Main"));
        }
      })
      .catch((err) => {
        dispatch(action.updateWidgetScreenState("Main"));
        const error: string =
          err.response.data.error.message || "Something went wrong";
        dispatchNotifications({
          message: error,
          color: "red",
        });
      });
  };

  return (
    <>
      <div className="cancel-screen">
        <div>
          <Cancel />
          <h6>
            {translateMessage(
              "Are you sure you want to cancel this transaction?",
            )}
          </h6>
          <div className="btn-grp">
            <button className="yes cursor" onClick={() => cancelCheckout()}>
              {translateMessage("Yes")}
            </button>
            <button
              className="no cursor"
              onClick={() => dispatch(action.updateWidgetScreenState("Main"))}
            >
              {translateMessage("No")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CancelScreen;
