import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import QRCode from "react-qr-code";
import QuotesSections from "./QuotesSections";
import BackBar from "./BackBar";
import { translateMessage } from "../../helper/constant";
import { initNetbanking } from "../../helper/api";

const QRCodeScreen = () => {
  const modalState = useSelector((state: ReduxState) => state.widgetReducer);

  let data = {
    checkoutId: modalState.checkoutData.checkoutId,
    isCryptoPay: true,
  };

  return (
    <>
      <BackBar className="py-0" />
      <div className="qr-screen">
        <div className="qr-sec wallet">
          <span>
            <a href="https://banking.globiancepay.com/register" target="_blank">
              Sign Up
            </a>{" "}
            now and secure your Free Virtual Credit Card!
          </span>
          <p>
            <span className="info-text text-center">
              SCAN the QR Code with your GlobiancePay Wallet
            </span>
          </p>
          <QRCode value={JSON.stringify(data)} />
          <div className="text">
            <p>
              <span className="or">{translateMessage("or")}</span>&nbsp;
              <span onClick={() => initNetbanking()} className="pointer login">
                {translateMessage("LOGIN")}
              </span>
            </p>
          </div>
        </div>
        {modalState.checkoutDetails.quotes &&
        modalState.checkoutDetails.quotes.length &&
        modalState.checkoutDetails.quotes.filter(
          (item) => item.currencyType === "wallet",
        ).length ? (
          <div className="avail-curr">
            <h5>{translateMessage("Available Currencies")}</h5>
            <QuotesSections
              showNetwork={false}
              quotes={modalState.checkoutDetails.quotes}
              filter="wallet"
              classN="qr"
            />
          </div>
        ) : (
          ""
        )}

        <div className="footer-IPS"></div>
      </div>
    </>
  );
};

export default QRCodeScreen;
