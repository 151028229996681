import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  FormatDateTime,
  FormatNumber,
  GetLogosrc,
  axiosInstance,
  translateMessage,
} from "../../helper/constant";
import PaymentDetailScreen from "./PaymentDetailScreen";
import { SpinnerCenter } from "../common/SpinnerCenter";
import RefreshIcon from "../../assets/images/refresh.svg";
import BackBar from "./BackBar";
import Space from "../common/Space";

const PaymentListItem = ({
  payment,
  onClick,
  active,
}: {
  payment: IPayments;
  onClick: any;
  active: string;
}) => {
  return (
    <div
      className={`quote-card cursor ${
        active
          ? payment.currencyId === active || payment.paymentId === active
            ? "active-pay"
            : ""
          : ""
      }`}
      onClick={() => {
        onClick(payment);
      }}
    >
      <div className="quote-card--left">
        <img src={GetLogosrc(payment.currencyName!)} alt="Currency Logo" />
        <div style={{ flexDirection: "column", alignItems: "flex-start" }}>
          <h5>{payment.currencyName}</h5>
          <div className="secondary-text">
            {FormatDateTime(payment.createdAt)}
          </div>
        </div>
      </div>
      <div className="quote-card--right">
        <h5>{FormatNumber(payment.amount!)}</h5>
        <div className="secondary-text" style={{ textTransform: "uppercase" }}>
          {payment.status}
        </div>
      </div>
    </div>
  );
};

const PaymentList = ({
  payments,
  setActive,
  active,
}: {
  payments: IPayments[];
  setActive: (payment: IPayments) => any;
  active: string;
}) => {
  return (
    <div className="quotes-section scroll-sec">
      {payments.map((payment, index) => (
        <PaymentListItem
          key={index + "-payment"}
          payment={payment}
          onClick={() => setActive(payment)}
          active={active}
        />
      ))}
    </div>
  );
};

const PaymentListScreen = () => {
  const modalState = useSelector((state: ReduxState) => state.widgetReducer);
  const [selectedPayment, setSelectedPayment] = useState<IPayments | null>(
    null,
  );
  const [lo, setLo] = useState(false);
  const [paymentDetail, setPaymentDetail] = useState<IPaymentDetail | null>(
    null,
  );

  const apiCall = () => {
    axiosInstance
      .post("/widget/get-payment-details", {
        apiKey: modalState.merchantData.apiKey,
        paymentId: selectedPayment!.paymentId,
      })
      .then((res) => {
        if (res.status === 200) {
          setPaymentDetail(res.data.data);
          setLo(false);
        }
      })
      .catch((err) => {
        setPaymentDetail(null);
        setSelectedPayment(null);
        setLo(false);
      });
  };

  useEffect(() => {
    if (selectedPayment) {
      setLo(true);
      apiCall();
    }
  }, [selectedPayment]);

  type GetStatus = {
    processing: "warning";
    confirmed: "success";
    refunded: "primary";
    disputed: "danger";
    "partially refunded": "info";
  };

  const getStatus: GetStatus = {
    processing: "warning",
    confirmed: "success",
    refunded: "primary",
    disputed: "danger",
    "partially refunded": "info",
  };

  return (
    <>
      <BackBar title="Payment List" />
      <div className="paymentList-screen">
        {modalState.checkoutDetails.payments && (
          <PaymentList
            payments={modalState.checkoutDetails.payments}
            setActive={(payment: IPayments) => setSelectedPayment(payment)}
            active={selectedPayment?.paymentId || ""}
          />
        )}
        <div className="payment-detail-sec">
          {selectedPayment ? (
            lo ? (
              <SpinnerCenter />
            ) : (
              paymentDetail && (
                <>
                  <div onClick={() => apiCall()} className="refresh-bar">
                    <p className="cursor">
                      <img src={RefreshIcon} alt="refresh" />
                      {translateMessage("Refresh")}
                    </p>
                  </div>
                  <PaymentDetailScreen paymentDetail={paymentDetail!} />
                  <Space height={"10px"} width={0} />
                </>
              )
            )
          ) : null}
        </div>
      </div>
    </>
  );
};

export default PaymentListScreen;
