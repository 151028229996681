import { useEffect } from "react";
import Expired from "../../assets/images/Expired";
import CopyCheckoutID from "./CopyCheckoutID";
import BackBar from "./BackBar";
import { translateMessage } from "../../helper/constant";

const ExpiryScreen = () => {
  useEffect(() => {
    localStorage.removeItem("checkoutID");
  }, []);

  return (
    <>
      <BackBar />
      <div className="expiry-screen">
        <Expired />
        <h1>{translateMessage("Expired")}</h1>
        <CopyCheckoutID />
      </div>
    </>
  );
};

export default ExpiryScreen;
