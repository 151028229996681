import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./app/app";
import reportWebVitals from "./reportWebVitals";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import widgetReducer from "./store/reducers/widgetReducer";
import globalReducer from "./store/reducers/globalReducer";
import thunk from "redux-thunk";
import { Provider } from "react-redux";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

const composeEnhancers = compose;

const rootReducer = combineReducers({
  widgetReducer: widgetReducer,
  globalReducer: globalReducer,
});

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk)),
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
);

export const Close = () => {
  store.dispatch({ type: "RESET" });
  localStorage.removeItem("checkoutID");
  window.close();
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
