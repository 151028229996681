import { useSelector } from "react-redux";
import ErrorFallback from "../common/ErrorFallback";
import MainPage from "./MainPage";

const OrderComponent = () => {
  const modalState = useSelector((state: ReduxState) => state.widgetReducer);

  return (
    <>
      {modalState.error && modalState.error.error ? (
        <ErrorFallback />
      ) : (
        <MainPage />
      )}
    </>
  );
};

export default OrderComponent;
