import * as actionTypes from "../actions/actionTypes";

const initialState: ModalState = {
  widgetState: "Expanded",
  widgetScreen: "Main",
  blockchainWallet: {
    loading: false,
    address: null,
    destinationTag: null,
  },
  widgetData: {
    amount: null,
    apikey: null,
    note: null,
    language: "en",
    theme: "light",
    currency: "USD",
  },
  merchantData: {
    apiKey: null,
    isActive: null,
    labelName: null,
    logo: null,
    showTaxId: null,
    taxId: null,
    taxRate: null,
  },
  error: {
    error: false,
    errorMessage: "",
    api: null,
  },
  checkoutData: {
    checkoutId: null,
    loading: false,
  },
  checkoutDetails: {
    amountInUSD: null,
    checkoutId: null,
    checkoutTime: null,
    duration: null,
    expiryTime: null,
    note: null,
    payments: [],
    quotes: [],
    requestCurrency: null,
    requestCurrencyAmount: null,
    requestCurrencyPriceUsd: null,
    status: null,
    itemName: null,
    clientId: null,
    items: [],
    store: {},
    enableFIATBuy: false,
  },
  paymentNotification: {
    message: "",
    color: "default",
  },
  loading: false,
  expired: false,
  back: "Main",
};

const widgetReducerSuccess = (
  state: ModalState,
  action: ModalAction,
): ModalState => {
  return {
    ...state,
    widgetState: action.data.widgetState!,
  };
};

const widgetParamsRecieve = (
  state: ModalState,
  action: ModalAction,
): ModalState => {
  return {
    ...state,
    widgetData: { ...state.widgetData, ...action.data.widgetData! },
  };
};

const checkoutDataRecieved = (
  state: ModalState,
  action: ModalAction,
): ModalState => {
  return {
    ...state,
    checkoutData: action.data.checkoutData!,
  };
};

const checkoutLoading = (
  state: ModalState,
  action: ModalAction,
): ModalState => {
  const data = {
    ...state.checkoutData!,
    loading: action.data.loading || false,
  };
  return {
    ...state,
    checkoutData: data,
  };
};

const merchantStateReducer = (
  state: ModalState,
  action: ModalAction,
): ModalState => {
  return {
    ...state,
    merchantData: action.data.merchantData!,
  };
};

const errorReducer = (state: ModalState, action: ModalAction): ModalState => {
  return {
    ...state,
    error: action.data.error!,
  };
};

const updateSreenStateReducer = (
  state: ModalState,
  action: ModalAction,
): ModalState => {
  return {
    ...state,
    widgetScreen: action.data.widgetScreen!,
  };
};

const updateTheme = (state: ModalState, action: ModalAction): ModalState => {
  return {
    ...state,
    widgetData: { ...state.widgetData, theme: action.data.theme! },
  };
};

export const selectedBlockchainReducer = (
  state: ModalState,
  action: ModalAction,
): ModalState => {
  const BCW = {
    ...state.blockchainWallet,
    ...action.data.blockchainWallet,
  };
  return {
    ...state,
    blockchainWallet: BCW,
  };
};

export const getCheckoutDetailSuccess = (
  state: ModalState,
  action: ModalAction,
): ModalState => {
  return {
    ...state,
    checkoutDetails: action.data.checkoutDetails!,
  };
};

export const updateNotification = (
  state: ModalState,
  action: ModalAction,
): ModalState => {
  return {
    ...state,
    paymentNotification: action.data.paymentNotification!,
  };
};

export const RESET = (): ModalState => {
  return initialState;
};

export const Expired = (state: ModalState): ModalState => {
  return {
    ...state,
    expired: true,
  };
};

// Global Reducer
const widgetReducer = (state = initialState, action: ModalAction) => {
  switch (action.type) {
    case actionTypes.UPDATE_MODAL_STATE:
      return widgetReducerSuccess(state, action);
    case actionTypes.UPDATE_WIDGET_PARAMS:
      return widgetParamsRecieve(state, action);
    case actionTypes.GET_MERCHANT_STORE_SUCCESS:
      return merchantStateReducer(state, action);
    case actionTypes.GET_MERCHANT_STORE_FAIL:
      return errorReducer(state, action);
    case actionTypes.GET_CHECHOUT_DATA_SUCCESS:
      return checkoutDataRecieved(state, action);
    case actionTypes.GET_CHECHOUT_DATA:
      return checkoutLoading(state, action);
    case actionTypes.UPDATE_WIDGET_SCREEN_STATE:
      return updateSreenStateReducer(state, action);
    case actionTypes.SELECTED_BLOCKCHAIN_WALLET:
      return selectedBlockchainReducer(state, action);
    case actionTypes.GET_BLOCKCHAIN_WALLET_ADDRESS:
      return selectedBlockchainReducer(state, action);
    case actionTypes.GET_BLOCKCHAIN_WALLET_ADDRESS_SUCCESS:
      return selectedBlockchainReducer(state, action);
    case actionTypes.GET_CHECKOUT_DETAIL_SUCCESS:
      return getCheckoutDetailSuccess(state, action);
    case actionTypes.UPDATE_PAYMENT_NOTIFICATION:
      return updateNotification(state, action);
    case actionTypes.RESET:
      return RESET();
    case actionTypes.EXPIRED:
      return Expired(state);
    case actionTypes.UPDATE_THEME:
      return updateTheme(state, action);
    default:
      return {
        ...state,
      };
  }
};

export default widgetReducer;
