import React from "react";

const Cancel = () => {
  return (
    <svg
      width="278"
      height="282"
      viewBox="0 0 278 282"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M256.779 194L153.722 15.5C147.179 4.16665 130.821 4.1667 124.278 15.5L21.2205 194C14.6772 205.333 22.8564 219.5 35.943 219.5H242.057C255.144 219.5 263.323 205.333 256.779 194Z"
        fill="#F9F9F9"
        stroke="#F5C534"
        strokeWidth="14"
      />
      <path
        d="M129.241 153.513V80.0018H148.83V153.513H129.241ZM151.608 172.745C151.608 176.354 150.397 179.346 147.975 181.721C145.601 184.095 142.633 185.282 139.071 185.282C135.51 185.282 132.518 184.095 130.096 181.721C127.674 179.346 126.463 176.354 126.463 172.745C126.463 169.184 127.674 166.216 130.096 163.841C132.565 161.42 135.557 160.209 139.071 160.209C142.585 160.209 145.553 161.42 147.975 163.841C150.397 166.216 151.608 169.184 151.608 172.745Z"
        fill="#F5C534"
      />
    </svg>
  );
};

export default Cancel;
