import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { GetCurrencySymbol } from "../../helper/misc";

function ItemDetails({ setItemDetails }: any) {
  const modalState = useSelector((state: ReduxState) => state.widgetReducer);

  return (
    <div className="item-details">
      <div className="back-button-bar">
        <p onClick={() => setItemDetails(false)}>{"< "}Go Back</p>
      </div>
      <h5>Checkout Breakdown</h5>
      <div className="tab-total">
        <Table>
          <thead>
            <tr>
              <th>Item Name</th>
              <th>QTY</th>
              <th>Rate</th>
              <th>Tax Rate</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {modalState.checkoutDetails.items &&
              modalState.checkoutDetails.items.map((item) => (
                <tr>
                  <td>{item.itemName}</td>
                  <td>{item.qty}</td>
                  <td>{item.price}</td>
                  <td>
                    {item.taxRate}
                    {" %"}
                  </td>
                  <td>{item.total}</td>
                </tr>
              ))}
          </tbody>
        </Table>
        <div className="total-section">
          <hr />
          <div>
            <p>Sub Total</p>
            <p>
              {GetCurrencySymbol(modalState.checkoutDetails.requestCurrency!)}{" "}
              {modalState.checkoutDetails.items?.reduce(
                (aV, cV) => aV + cV.subTotal,
                0,
              )}
            </p>
          </div>
          <div>
            <p>Total Tax</p>
            <p>
              {GetCurrencySymbol(modalState.checkoutDetails.requestCurrency!)}{" "}
              {modalState.checkoutDetails.items?.reduce(
                (aV, cV) => aV + cV.taxAmount,
                0,
              )}
            </p>
          </div>
          <div>
            <p>Total</p>
            <p>
              {GetCurrencySymbol(modalState.checkoutDetails.requestCurrency!)}{" "}
              {modalState.checkoutDetails.items?.reduce(
                (aV, cV) => aV + cV.total,
                0,
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ItemDetails;
