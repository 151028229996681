import { useEffect } from "react";
import { useSelector } from "react-redux";
import ErrorImage from "../../assets/images/error-img.png";
import { useDispatch } from "react-redux";
import * as actions from "../../store/actions";
import CopyCheckoutID from "../widget/CopyCheckoutID";
import { translateMessage } from "../../helper/constant";

const ErrorFallback = () => {
  const dataState = useSelector((state: ReduxState) => state.widgetReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.updateWidgetScreenState("Error"));
  }, []);

  return (
    <div className="error-screen">
      <h3 className="mt-3">{translateMessage("Something Went Wrong")}</h3>
      {dataState.error.error && (
        <h6>{dataState.error.errorMessage.toLocaleUpperCase()}</h6>
      )}
      <img src={ErrorImage} alt="Error" />
      {dataState.checkoutDetails.checkoutId && <CopyCheckoutID />}
      <h6>{translateMessage("CONTACT SUPPORT")}</h6>
    </div>
  );
};

export default ErrorFallback;
