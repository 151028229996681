import { FormatNumber, GetLogosrc } from "../../helper/constant";
import { useDispatch } from "react-redux";
import GBPay from "../../assets/images/GBPay_logo.png";
import GBPayLogo from "../../assets/images/GBPay_logo_crop.png";
import { GetCurrencySymbol } from "../../helper/misc";
import { useSelector } from "react-redux";
import { updateWidgetScreenState } from "../../store/actions";
import UnionPay from "../../assets/images/unionpay_white.png";
import A1 from "../../assets/images/a1.png";
import A2 from "../../assets/images/a2.png";
import A3 from "../../assets/images/a3.png";
import A4 from "../../assets/images/a4.png";
import A5 from "../../assets/images/a5.png";

import Image1 from "../../assets/images/visa_black.png";
import Image1_W from "../../assets/images/visa_white.png";
import Image2 from "../../assets/images/Applepay_black.png";
import Image2_W from "../../assets/images/Applepay_white.png";
import Image3 from "../../assets/images/Googlepay_black.png";
import Image3_W from "../../assets/images/Googlepay_white.png";
import Image4 from "../../assets/images/swift_black.png";
import Image4_W from "../../assets/images/swift_white.png";
import Image5 from "../../assets/images/mastercar_black.png";
import Image5_W from "../../assets/images/mastercard_white.png";
import Image6 from "../../assets/images/skrill_black.png";
import Image6_W from "../../assets/images/skrill_white.png";
import Image7 from "../../assets/images/neteller_black.png";
import Image7_W from "../../assets/images/neteller_white.png";

interface Props {
  quotes: IQuote[];
  filter?: string;
  onClick?: Function;
  active?: string;
  mH?: string;
  showNetwork?: boolean;
  classN: string;
}

const StaticCard = () => {
  const modalState = useSelector((state: ReduxState) => state.widgetReducer);
  const dispatch = useDispatch();

  return (
    <div
      className="static-card"
      onClick={() => dispatch(updateWidgetScreenState("QR"))}
    >
      <div className="static-card--left">
        <img src={GBPay} alt="GlobiancePay Logo" className="logo" />
        <div className="payment-methods">
          <svg
            height="20"
            viewBox="0 0 28 10"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M25.9257 9.39606L25.6632 8.08355H22.7302L22.2635 9.38672L19.9127 9.39139C21.4235 5.75956 22.5458 3.07039 23.2773 1.32156C23.4687 0.864222 23.8082 0.630889 24.3087 0.634389C24.6913 0.637889 25.3155 0.637889 26.1823 0.635555L28 9.39256L25.9257 9.39606ZM23.3893 6.28572H25.2805L24.5747 2.99572L23.3893 6.28572ZM8.239 0.633222L10.6027 0.635555L6.94867 9.39722L4.55583 9.39489C3.95413 7.08059 3.3599 4.76436 2.77317 2.44622C2.6565 1.98422 2.4255 1.66106 1.981 1.50822C1.32363 1.28865 0.663232 1.07824 0 0.877055L0 0.635555H3.7765C4.42983 0.635555 4.81133 0.951723 4.93383 1.60039C5.0575 2.25022 5.36783 3.90572 5.86717 6.56339L8.239 0.633222ZM13.8507 0.635555L11.9828 9.39606L9.73233 9.39256L11.5967 0.633222L13.8507 0.635555ZM18.4123 0.473389C19.0855 0.473389 19.9337 0.683388 20.4213 0.877055L20.027 2.69239C19.586 2.51506 18.8603 2.27589 18.2502 2.28406C17.3635 2.29922 16.8152 2.67139 16.8152 3.02839C16.8152 3.60939 17.7672 3.90222 18.7472 4.53689C19.8648 5.26022 20.013 5.91005 19.999 6.61705C19.9838 8.08122 18.7472 9.52672 16.1385 9.52672C14.9485 9.50922 14.5192 9.41006 13.5485 9.06472L13.9592 7.17005C14.9473 7.58306 15.3662 7.71489 16.2108 7.71489C16.9843 7.71489 17.6482 7.40222 17.654 6.85739C17.6587 6.47006 17.4207 6.27756 16.5527 5.79922C15.6847 5.31972 14.4667 4.65705 14.483 3.32355C14.5028 1.61789 16.1187 0.473389 18.4123 0.473389Z" />
          </svg>

          <svg
            height="20"
            viewBox="0 0 26 16"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M13.0012 1.76304C13.9458 2.50451 14.7093 3.45116 15.2341 4.53132C15.7588 5.61147 16.0309 6.79685 16.0298 7.99771C16.0304 9.19828 15.7579 10.3833 15.233 11.463C14.7081 12.5427 13.9445 13.4889 13 14.23C12.0555 13.4887 11.2921 12.5422 10.7673 11.4623C10.2426 10.3824 9.97043 9.19721 9.97132 7.99654C9.97025 6.79568 10.2424 5.61031 10.7671 4.53015C11.2918 3.44999 12.0554 2.50335 13 1.76188M11.985 1.08288C10.9854 1.93673 10.1831 2.99745 9.63358 4.19174C9.08408 5.38603 8.80046 6.6854 8.80232 8.00004C8.80096 9.3141 9.08483 10.6128 9.63432 11.8064C10.1838 13.0001 10.9858 14.0602 11.985 14.9137C10.7779 15.5913 9.41456 15.9412 8.03039 15.9286C6.64621 15.9159 5.28944 15.5413 4.09494 14.8418C2.90045 14.1423 1.90983 13.1423 1.22156 11.9413C0.533291 10.7403 0.171342 9.38012 0.171692 7.99589C0.172042 6.61165 0.53468 5.25161 1.22356 4.05097C1.91243 2.85032 2.90356 1.85087 4.09841 1.15199C5.29325 0.4531 6.65021 0.079108 8.0344 0.0671886C9.41858 0.0552693 10.7818 0.405837 11.9885 1.08404M14.0208 14.9137C15.0198 14.0601 15.8216 12.9999 16.3709 11.8063C16.9201 10.6126 17.2038 9.314 17.2023 8.00004C17.2037 6.68542 16.9197 5.38616 16.3698 4.19207C15.8198 2.99799 15.0172 1.93755 14.0173 1.08404C15.224 0.405837 16.5872 0.0552693 17.9714 0.0671886C19.3556 0.079108 20.7126 0.4531 21.9074 1.15199C23.1023 1.85087 24.0934 2.85032 24.7823 4.05097C25.4711 5.25161 25.8338 6.61165 25.8341 7.99589C25.8345 9.38012 25.4725 10.7403 24.7843 11.9413C24.096 13.1423 23.1054 14.1423 21.9109 14.8418C20.7164 15.5413 19.3596 15.9159 17.9754 15.9286C16.5913 15.9412 15.2279 15.5913 14.0208 14.9137Z" />
          </svg>

          <img src={UnionPay} alt="Union Pay" className="method-logo" />
        </div>
      </div>
      <div className="static-card--right">
        <h5>
          {GetCurrencySymbol(modalState.checkoutDetails.requestCurrency!)}{" "}
          {modalState.checkoutDetails.requestCurrencyAmount}
        </h5>
      </div>
    </div>
  );
};

const SignupStaticCard = () => {
  return (
    <div
      className="static-card"
      onClick={() =>
        window.open("https://banking.globiancepay.com/register", "_blank")
      }
    >
      <div className="static-card--left">
        <img src={GBPayLogo} alt="GlobiancePay Logo" className="logo" />
        <p className="mr-1">Signup for GlobiancePay Wallet</p>
      </div>
    </div>
  );
};

const PaybyCard = () => {
  const modalState = useSelector((state: ReduxState) => state.widgetReducer);
  const dispatch = useDispatch();

  return (
    <div
      className="quote-card cursor px-2 py-1"
      onClick={() => dispatch(updateWidgetScreenState("BuyCrypto"))}
    >
      <div className="quote-card--left w-100 justify-content-between">
        <p className="mr-1 pay-by-card-logo-text">Pay by Card or APMs</p>
        <div className="pay-by-card-logo-container">
          {modalState.widgetData.theme === "light" && (
            <>
              <img src={Image1} className="light" alt="" />
              <img src={Image2} className="light" alt="" />
              <img src={Image3} className="light" alt="" />
              <img src={Image4} className="light" alt="" />
              <img src={Image5} className="light" alt="" />
              <img src={Image6} className="light" alt="" />
              <img src={Image7} className="light" alt="" />
            </>
          )}
          {modalState.widgetData.theme === "dark" && (
            <>
              <img src={Image1_W} className="dark" alt="" />
              <img src={Image2_W} className="dark" alt="" />
              <img src={Image3_W} className="dark" alt="" />
              <img src={Image4_W} className="dark" alt="" />
              <img src={Image5_W} className="dark" alt="" />
              <img src={Image6_W} className="dark" alt="" />
              <img src={Image7_W} className="dark" alt="" />
            </>
          )}
          <p>And more</p>
        </div>
      </div>
    </div>
  );
};

const AsianPayments = () => {
  const dispatch = useDispatch();

  return (
    <div
      className="quote-card cursor h-70"
      onClick={() => dispatch(updateWidgetScreenState("BuyCrypto"))}
    >
      <div className="quote-card--left">
        <p className="mr-1 p-text">
          Asian Payments <br />
          <span> (Coming Soon)</span>
        </p>
      </div>
      <div className="quote-card--right">
        <div className="imgs">
          <img src={A1} />
          <img src={A2} />
          <img src={A3} />
          <img src={A4} />
          <img src={A5} />
        </div>
      </div>
    </div>
  );
};

const QuoteCard = ({
  quote,
  onClick,
  active,
  showNetwork = true,
  filter,
}: {
  quote: IQuote;
  onClick: Function;
  active?: string;
  filter?: string;
  showNetwork?: boolean;
}) => {
  let network: any = <div style={{ height: "15px" }}></div>;

  if (showNetwork && quote.network) {
    const availableNetworks = quote.network.split(",");
    network = availableNetworks.map((x, i) => {
      return <img key={`${x}-${i}`} src={GetLogosrc(x)} alt="Currency Logo" />;
    });
  }

  return (
    <div
      className={`quote-card cursor ${
        active
          ? quote.currencyId === active || quote.paymentId === active
            ? "active-pay"
            : ""
          : ""
      }`}
      onClick={() => {
        onClick(quote);
      }}
    >
      <div className="quote-card--left">
        <img src={GetLogosrc(quote.currencyName!)} alt="Currency Logo" />
        <h5>
          {quote.currencyName} <span>{quote.currencyFullName}</span>
        </h5>
      </div>
      <div className="quote-card--right">
        <h5>
          {FormatNumber(
            filter && filter === "wallet"
              ? quote.total!
              : quote.subTotal! || quote.amount!,
          )}
        </h5>
        <div className="network">{network}</div>
      </div>
    </div>
  );
};

const QuotesSections = ({
  quotes,
  filter,
  onClick,
  active,
  mH,
  classN,
  showNetwork = true,
}: Props) => {
  const cb = (quote: IQuote) =>
    filter && filter === "blockchain" ? () => {} : () => null;
  return (
    <div className={`quotes-section scroll-sec ${classN}`}>
      {classN === "main" && <StaticCard />}
      {classN === "main" && <PaybyCard />}
      {filter
        ? quotes
            .filter((item) => item.currencyType === filter)
            .map((quote, index) => (
              <>
                <QuoteCard
                  showNetwork={showNetwork}
                  filter={filter}
                  key={index + "Quote"}
                  quote={quote}
                  onClick={cb(quote)!}
                />
              </>
            ))
        : quotes.map((quote, index) => (
            <>
              <QuoteCard
                showNetwork={showNetwork}
                key={index + "-Quote"}
                quote={quote}
                onClick={() => onClick && onClick(quote)}
                active={active}
              />
            </>
          ))}
      {classN === "main" && (
        <>
          <AsianPayments />
          <SignupStaticCard />
        </>
      )}
    </div>
  );
};

export default QuotesSections;
