import { store } from "..";
import {
  NetbankingUrl,
  axiosInstance,
  dispatchNotifications,
} from "./constant";

export const initNetbanking = () => {
  const storeState: ReduxState = store.getState();

  let data = {
    apiKey: storeState.widgetReducer.merchantData.apiKey,
    checkoutId: storeState.widgetReducer.checkoutData.checkoutId,
  };

  axiosInstance
    .post("/widget/initiate-pay-with-wallet", data)
    .then((response) => {
      if (response.status === 200) {
        let sessionId = response.data.data.sessionId;
        let expiresInMS = response.data.data.expiresInMS;
        window.open(
          `${NetbankingUrl}/?sessionId=${sessionId}&expiresInMS=${expiresInMS}`,
          "_blank",
        );
        dispatchNotifications({
          message: `Widget Initiated`,
          color: "green",
        });
        return;
      }
      dispatchNotifications({
        message: `Error initializing widget`,
        color: "red",
      });
    })
    .catch((error) => {
      dispatchNotifications({
        message: `Error initializing widget`,
        color: "red",
      });
    });
};
