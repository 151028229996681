import * as actionTypes from "./actionTypes";
import axios from "axios";

export const tokenAndPairPrecisionDataStart = () => {
  return {
    type: actionTypes.GET_TOKEN_AND_PAIR_PRECISION_DATA_START,
  };
};

export const tokenAndPairPrecisionDataSuccess = (
  data: any,
  pairId?: any,
  pair?: any,
) => {
  return {
    type: actionTypes.GET_TOKEN_AND_PAIR_PRECISION_DATA_SUCCESS,
    data: data,
    pairId: pairId,
    pair: pair,
  };
};

export const tokenAndPairPrecisionDataFail = (error: any) => {
  return {
    type: actionTypes.GET_TOKEN_AND_PAIR_PRECISION_DATA_FAIL,
    error: error,
  };
};

export const getMetsdataSuccess = (data: any) => {
  return {
    type: actionTypes.GET_METADATA_SUCCESS,
    data: data,
  };
};

export const getTokenAndPairsPrecision = () => {
  return (dispatch: DispatchType) => {
    dispatch(tokenAndPairPrecisionDataStart());

    axios
      .get(process.env.REACT_APP_ASSET_METADATA_URL!)
      .then((res) => {
        if (res.status === 200) {
          dispatch(getMetsdataSuccess(res.data.data));
        }
      })
      .catch((err) => null);
  };
};
